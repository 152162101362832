import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid } from "@material-ui/core"
import { useSelector } from "react-redux"
import TourCard from "./TourCard"

const TourCards = ({ locale, length }) => {
  const data = useStaticQuery(graphql`
    query tourCardsQuery {
      allContentfulTourPageModel {
        edges {
          node {
            node_locale
            tourDetailCardsReferences {
              slug
              tourLocationDescription
              tourTitle
              tourPricingReference {
                isFixedPriceTour
                fixedTourPrice
                perPersonPricing
              }
              cardDescription {
                cardDescription
              }
              tourMainImage {
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const localeCardsData = data.allContentfulTourPageModel.edges.filter(
    ({ node }) => node.node_locale === locale
  )

  const tourCards = localeCardsData[0].node.tourDetailCardsReferences
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const currency = translations?.euro

  const trimmedCards = length ? tourCards.slice(0, length) : tourCards

  return (
    <Grid container spacing={3} justify="center">
      {trimmedCards.map(
        ({
          slug,
          tourLocationDescription,
          tourTitle,
          cardDescription,
          tourMainImage,
          tourPricingReference,
        }) => (
          <Grid key={slug} item xs={12} md={6} lg={3}>
            <TourCard
              slug={slug}
              tourLocationDescription={tourLocationDescription}
              tourTitle={tourTitle}
              cardDescription={cardDescription}
              tourMainImage={tourMainImage}
              tourPricingReference={tourPricingReference}
              currencyString={currency}
            />
          </Grid>
        )
      )}
    </Grid>
  )
}

export default TourCards
