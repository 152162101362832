import React from "react"
import { Container, Typography, withWidth } from "@material-ui/core"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import { graphql } from "gatsby"
import { Destinations } from "../components/Destinations/Destinations"
import CustomTourSection from "../components/CustomTourSection"
import TripAdvisor from "../components/TripAdvisor"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import TextField from "../components/TextField"
import TourCards from "../components/TourCards"
import { useSelector } from "react-redux"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import CustomDivider from "../components/CustomDivider"
import { SEO } from "../components/SEO"

export const query = graphql`
  query($locale: String!) {
    allContentfulDmcPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          queryDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulTourPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          pageSubTitle
          seoTitle
          seoDescription
          generalText {
            content {
              content {
                nodeType
                value
              }
              nodeType
            }
          }
          customTourSectionTitle
          customTourItineraryTitles
          customTourItineraryDescriptions
          availableLocationsTitle
          availablePortugalLocations
          availableSpainLocations
          destinationSectionTitle
          destinationSectionDescription
          customTourSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          customTourItineraryThumbnails {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          querySectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          customTourSectionDescription {
            customTourSectionDescription
          }
          portugalFlag {
            fixed(height: 16) {
              ...GatsbyContentfulFixed
            }
          }
          spainFlag {
            fixed(height: 16) {
              ...GatsbyContentfulFixed
            }
          }
          destinationCardsReferences {
            slug
            cityName
            countryFlag {
              fluid(maxWidth: 20) {
                ...GatsbyContentfulFluid
              }
            }
            cardTruncateDescription {
              cardTruncateDescription
            }
            bannerImage {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    allContentfulLandingPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          queryTopics
          hateContactFormTitle
          hateContactFormSubTitle
          hateContactFormLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          hateContactFormLogosId
        }
      }
    }
  }
`

const toursTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    secondary: {
      main: "#FF9486",
      contrastText: "#010204",
      dark: "#ff5252",
    },
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(12.5),
    },
  },
  sectionWrapper: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  title: {
    fontSize: "3rem",
    lineHeight: 1,
    [theme.breakpoints.up("md")]: {
      fontSize: "5rem",
    },
  },
  subtitle: {
    fontSize: "1rem",
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
      paddingTop: theme.spacing(3),
    },
  },
}))

const Tours = ({
  location,
  pageContext,
  width,
  data: { allContentfulTourPageModel },
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const {
    pageTitle,
    pageSubTitle,
    seoTitle,
    seoDescription,
    generalText,
    customTourSectionDescription,
    customTourSectionTitle,
    customTourItineraryTitles,
    customTourItineraryDescriptions,
    customTourItineraryThumbnails,
    customTourSectionImage,
    availableLocationsTitle,
    availablePortugalLocations,
    availableSpainLocations,
    querySectionImage,

    portugalFlag,
    spainFlag,
  } = allContentfulTourPageModel.edges[0].node

  const classes = useStyles()

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      backgroundcolor={"#FAF5EF"}
      selected={"tours"}
      button={"#FF9A8B"}
    >
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <div
        style={{ backgroundColor: "#FAF5EF" }}
        className={locale === "zh" && "chinese-version"}
      >
        <ThemeProvider theme={toursTheme}>
          <Container className={classes.root}>
            <Typography variant="h1" align="center" className={classes.title}>
              {pageTitle}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.subtitle}
              gutterBottom
            >
              {pageSubTitle}
            </Typography>
            <section className={classes.sectionWrapper}>
              <TourCards locale={pageContext.locale} />
            </section>
          </Container>
          <CustomDivider extendRight />
          <CustomTourSection
            customTourSectionImage={customTourSectionImage}
            customTourSectionTitle={customTourSectionTitle}
            translations={translations}
            customTourItineraryTitles={customTourItineraryTitles}
            customTourSectionDescription={customTourSectionDescription}
            customTourItineraryThumbnails={customTourItineraryThumbnails}
            availableLocationsTitle={availableLocationsTitle}
            availablePortugalLocations={availablePortugalLocations}
            portugalFlag={portugalFlag}
            spainFlag={spainFlag}
            availableSpainLocations={availableSpainLocations}
            customTourItineraryDescriptions={customTourItineraryDescriptions}
          />
          <CustomDivider extendLeft />
          <Destinations locale={locale} />
          <CustomDivider extendRight />
          <TextField
            locale={pageContext.locale}
            defaultValue="Tours"
            customImage={querySectionImage}
          />
          <SEO
            seoData={{
              generalText,
            }}
            isGenText
          />
          <CustomDivider extendLeft />
          <TripAdvisor
            locale={locale}
            translations={translations}
            width={width}
          />
        </ThemeProvider>

        <Footer locale={pageContext.locale} hidePartners />
      </div>
    </Layout>
  )
}
export default withWidth()(Tours)
