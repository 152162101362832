import React from "react"
import Img from "gatsby-image"
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  Box,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import LocalizedLink from "./LocalizedLink"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  imageSection: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(7),
    },
  },
  descriptionWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  thumbnail: {
    width: 48,
  },
  overline: {
    fontFamily: "MabryProLight",
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  featuresBox: {
    padding: theme.spacing(5, 0),
  },
  featureTitle: {
    fontSize: "1rem",
    lineHeight: "20px",
  },
  featureDescription: {
    fontSize: "0.875rem",
    lineHeight: "18px",
    paddingTop: theme.spacing(0.5),
  },
  card: {
    marginTop: theme.spacing(7),
  },
  countryFlag: {
    width: 16,
    clipPath: "circle(8px)",
    display: "inline-block",
  },
  availableCities: {
    fontSize: "0.875rem",
    lineHeight: "18px",
  },
}))

const CustomTourSection = ({
  customTourSectionImage,
  customTourSectionTitle,
  translations,
  customTourItineraryTitles,
  customTourSectionDescription,
  customTourItineraryThumbnails,
  availableLocationsTitle,
  availablePortugalLocations,
  portugalFlag,
  spainFlag,
  availableSpainLocations,
  customTourItineraryDescriptions,
}) => {
  const classes = useStyles()

  const AvailableCities = ({ flag, locations }) => (
    <Box display="flex" py={0.5}>
      <Img fixed={flag.fixed} className={classes.countryFlag} />
      <Typography className={classes.availableCities}>
        {locations.map((eachLocation, index) =>
          index === 0 ? (
            <span>{eachLocation}</span>
          ) : index === locations.length - 1 ? (
            <span>{" & " + eachLocation}</span>
          ) : (
            <span>, {eachLocation}</span>
          )
        )}
      </Typography>
    </Box>
  )

  return (
    <Container>
      <Grid container className={classes.root} wrap="wrap-reverse">
        <Grid item xs={12} md={6} className={classes.imageSection}>
          <Img fluid={customTourSectionImage.fluid} />
        </Grid>
        <Grid item xs={false} md={1} />
        <Grid item xs={12} md={4}>
          <Typography variant="overline" className={classes.overline}>
            {customTourSectionTitle}
          </Typography>

          <Typography variant="h2" component="h4" className={classes.title}>
            {translations?.createYourOwnTour}
          </Typography>

          <Typography>
            {customTourSectionDescription?.customTourSectionDescription}
          </Typography>

          <Grid container spacing={3} className={classes.featuresBox}>
            {customTourItineraryTitles.map((eachTitle, index) => {
              return (
                <Grid item xs={12} md={6} key={index}>
                  <Box className={classes.descriptionWrapper}>
                    <Box pr={2}>
                      <Img
                        className={classes.thumbnail}
                        fluid={customTourItineraryThumbnails?.[index]?.fluid}
                      />
                    </Box>
                    <Box pt={{ xs: 0, md: 1.5 }}>
                      <Typography variant="h6" className={classes.featureTitle}>
                        {eachTitle}
                      </Typography>
                      <Typography className={classes.featureDescription}>
                        {customTourItineraryDescriptions[index]}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            component={LocalizedLink}
            to="/MapScreen"
          >
            {translations?.createTour}
          </Button>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.featureTitle}>
                {availableLocationsTitle}
              </Typography>
              <Box pt={1}>
                <AvailableCities
                  flag={portugalFlag}
                  locations={availablePortugalLocations}
                />
                <AvailableCities
                  flag={spainFlag}
                  locations={availableSpainLocations}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CustomTourSection
